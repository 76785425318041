import React, { createRef, useEffect, useState } from "react";
import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonModal, IonNote, IonPage, IonRadio, IonRadioGroup, IonRow, IonSelect, IonText, IonTitle, IonToast, IonToolbar } from "@ionic/react";
import { close } from 'ionicons/icons';
import SignaturePad from "react-signature-canvas"
import {UserDetails} from "../api/model/UserDetails";
import ReactSignatureCanvas from "react-signature-canvas";
import { Markup } from 'interweave';
import {config} from '../Constants'
import { base64FromPath } from '@ionic/react-hooks/filesystem';
import { arrayAsString, IndexOutOfBoundsError, PDFDocument } from "pdf-lib";
import { title } from "process";
import {ApiService} from "../api/ApiService";
import { userStore, UserState } from "../api/AuthService";
import { DriverProfile } from "../api/model/DriverProfile";
import { toastStore } from "../components/AppToast";

const Agrmt:any = ["&%", "...", "..."];
// .. Containers for passing values - resp to Acknowledgement(s)
const ccAgrmt:any = ["..."];
const relAgrmt:any = ["..."];

interface iData {
  // .. call as iData JSON "Constructor" **NOT USED
  name: string;
  resp: string;
  // .. DTO - "Constructor"
  conduct_Acknwldg: boolean;
  relations_Acknwldg: boolean;
  
  compile(func:string): void;
}

interface iAgreement {
    title : string,
    btnTitle: string,
    doc: string,
    initial: boolean,
    e_signID: number,
  }

const Agreements: React.FC = () => {

  const [showTrainingCode, setShowTrainingCode] = useState(false);
  const [showRelation, setShowRelation] = useState(false);
  const [showSafetyBeltPolicy, setShowSafetyBeltPolicy] = useState(false);
  const [agreeConduct, setAgreeConduct] = useState(false)
  const [agreeRelation, setAgreeRelation] = useState(false)
  const [agreeSafetyBeltPolicy, setAgreeSafetyBeltPolicy] = useState(false)
  const [dataURL, setDataURL] = useState("");
  const [ssn, setSSN] = useState("");

const [showModal, setShowModal] = useState(false);

const [agreementTitle, setAgreementTitle] = useState('');
const [btnTitle, setBtnTitle] = useState('');
const [agreementDoc, setAgreementDoc] = useState('');
const today = new Date();

const [toast, setToast] = useState("");
const [showToast, setShowToast] = useState(false);

const [alertToast, set_AlertToast] = useState("");
const [show_AlertToast, setShow_AlertToast] = useState(false);


const [ eSign, set_eSign ] = useState<number>(0);
const [ eSigned, set_eSigned ] = useState(false);
const [ signature, setSignature]  = useState('');

const [driverSignatureData, setDriverSignatueData] = useState("")
const [showDriverSignature, setShowDriverSignature] = useState(true)

const [ isConduct, setIsConduct ] = useState(false);

const [ relations, setRelations] = useState(false);
const [ isRelation, setIsRelation ] = useState(false);

const [ initial, setInitial ] = useState('');
const [ equalSig, setEqualSig ] = useState('');

const [driverHash, setDriverHash] = useState('')
const [noDocFound, setNoDocFound] = useState(false);

const {userDetails}: { userDetails: UserDetails } = userStore((state: UserState) => ({userDetails: state.user}));
const {userInfo, username}: { userInfo: DriverProfile, username: string } = userStore((state: UserState) => ({userInfo: state.driver, username: state.username}));

    const driverSigCanvas = createRef<ReactSignatureCanvas>()

  /* A function that uses the canvas ref to clear the canvas 
  via a method given by react-signature-canvas */
    
  //Supply localStorage eSignature 'JSON object' as DTO (Data-Transfer Object) 
  function confirm_Signatures(){
    let data = localStorage.getItem("signature");
    
    if (data !== null){
      var eSign_DTO:iData = JSON.parse(data);
      console.log(eSign_DTO);
      return eSign_DTO;
    }
  }
  
  /*
  function clearData_Signature(){
    localStorage.removeItem("signature");
    setConduct(false);
    setRelations(false);
  }*/

  //update -  fetch. request:
  function handleAgreement (resp:any) {
    if (resp.initial === true){
      setToast("Success! - " + resp[eSign].btnTitle + " signature confirmed");
      setShowToast(true);
    }
  }
  
  //JS async - complete format & parse JSON object(string?) [...] **see: confirm_Acknowledgement
  function parse_Agreement(conduct:any[], relations:any[]){
  
    var stdby_format = (localStorage.getItem('signature'));
    if(stdby_format !== null){ 
      try {
        let output = JSON.parse(stdby_format);
        console.log(output);
        set_eSigned(true);
        console.log("&%" + eSigned);
        
        return output;
         
      } catch {
        
          let format = "{" + conduct + "," + relations + "}";
          localStorage.setItem("signature", format);

          return format;
        

      } finally {
        if(eSigned === true){
            setToast("Agreements have been acknowledged! -- Please sign below");
            setShowToast(true);
            console.log("isConduct: " + conduct);
            console.log("isRelations: " + relations);
            console.log("is_eSignature: " + signature);
            console.log("SignaturesComplete?: " + eSigned);
        } 
      }
    }
  }

  // Format eSignature(s) into JSON object & parse_Agreement [...]
  function confirm_Acknowledgement(array:any, name:string, resp:boolean) {
    let val = document.querySelector(".eSign");
    let check_eSign = val?.getAttribute("aria-checked");
    //let eSign_Data = confirm_Signatures();
    
    console.log(check_eSign);
    if (check_eSign === "false") {
      set_AlertToast("Please indicate your acknowledgement of these contents ... " + name);
      setShow_AlertToast(true);
      setShowModal(true);
      
    } else if(check_eSign === 'true') {
      try { 
      
        if (eSigned) {
          setToast("All documents have been signed! - please sign and submit forms (below)");
          setShowToast(true);

        } else if (!eSigned){
          let compile = '\"' + name + '\":\"' + resp + '\"';
          let format = ccAgrmt.toString() + relAgrmt.toString() + "}";
              localStorage.setItem("signature", format);
              console.log(format);
        
          if (array.indexOf("...") === 0){
            array.pop();
            array.push(compile);
          } 
        }   
        parse_Agreement(ccAgrmt, relAgrmt);
        setShowModal(false);
      } catch {
 
      } finally {
        console.log(localStorage.getItem("signature"));
        console.log(array[0]);
      } 
    }   
  }

  const saveDriver = () => {
    const signed = driverSigCanvas.current!.toDataURL()
    setDriverSignatueData(signed)
    setShowDriverSignature(!showDriverSignature)
}

  const clearDriver = () => driverSigCanvas.current!.clear()

  // .. (Above) submit request to Wemby v.# -- (Below) open appropriate .doc & confirm_Signature [...]

  function openAgreement (agree: any) {
    let obj:iAgreement = {
      title: agree.title,
      btnTitle: agree.btnTitle,
      doc: "",
      initial: agree.initial,
      e_signID: agree.e_signID
    };

    setAgreementTitle(agree.title);
    setBtnTitle(agree.btnTitle);
    setAgreementDoc(agree.doc);
    set_eSign(agree.e_signID);

    
    // -- Document recognition -by- index(e_sign)
    try {
      let data = localStorage.getItem("signature");
      let eSign_Data = confirm_Signatures(); // .. eSign_Data.name - eSign_Data.resp
      console.log(eSign_Data)
      //let format = localStorage.getItem("signature");
      if (eSign_Data !== undefined){
        if(data !== null){
          /* Define *ALT* routing for "Code of Conduct" & "Relations Agreement"

          let val = document.querySelector(".eSign_DTO");
          val?.setAttribute('aria-checked', eSign_Data.conduct_Acknwldg);

          */
          console.log("ConductSigned: " + eSign_Data.conduct_Acknwldg);
          console.log("RelationSigned: " + eSign_Data.relations_Acknwldg);

        }
      }
    } catch {
     
    } finally {
      if(agree.e_signID === 0){
        setIsConduct(true);
        setIsRelation(false);
      } else if (agree.e_signID === 1){
        setIsConduct(false);
        setIsRelation(true);
      }
    }
    setInitial(agree.initial);
    setEqualSig(agree.equalSig);
    setShowModal(true);

    console.log(obj);
  }

  const checkLink = (link: any) => {
    const requestOptions = {
      method: 'GET',
      headers: {}
    };
    fetch('https://westernexpressmobile.com/agreements/' + link + "-0.pdf", requestOptions)
    .then(response => {
      if(response.status === 404){console.log("404");}})
    .catch(function(err) {
      console.log(err);
      setNoDocFound(true);
    })
  }

  const agreementHandler = () => {
    if (noDocFound) {
      setToast("No Contract Found");
      setShowToast(true)
    } else {
      window.open('https://westernexpressmobile.com/agreements/' + driverHash + "-0.pdf", '_blank')
    }
  }

  async function fillForm() {
    if(driverSignatureData === "" && ssn.length === 9){
      toastStore.getState().setToast('Please save driver signature and write ssn.');
    }
      const formURL = "./assets/Trainer_Docs_Trainer_Code_of_conduct_Trainer_Expectations_Seatbelt_Sleeper_Birth_policy.pdf"
      const pdfFormBytes = await fetch(formURL).then(res => res.arrayBuffer())

      const pdfDoc = await PDFDocument.load(pdfFormBytes)
      const form = pdfDoc.getForm()

      const fields = form.getFields()

      fields.forEach((field: any) => {
          const type = field.constructor.name
          const name = field.getName()
          console.log(`${type}: ${name}`)
      })

      var trainerName1Field = form.getTextField('TRAINER PRINTED NAME')
      var date1 = form.getTextField('DATE')
      var trainerName2Field = form.getTextField('TRAINER PRINTED NAME 2')
      var date2 = form.getTextField('DATE_2')
      var driverCodeField = form.getTextField('DRIVER CODE')
      var namePrint = form.getTextField('Print Name')
      var date3 = form.getTextField('DATE_3')
      
      trainerName1Field.setText(userDetails.firstName + " " + userDetails.lastName);
      date1.setText(new Date().toLocaleDateString().slice(0,10));
      trainerName2Field.setText(userDetails.firstName + " " + userDetails.lastName);
      date2.setText(new Date().toLocaleDateString().slice(0,10));
      driverCodeField.setText(username);
      namePrint.setText(userDetails.firstName + " " + userDetails.lastName);
      date3.setText(new Date().toLocaleDateString().slice(0,10));

      const driverSignatureBytes = await fetch(driverSignatureData).then((res) => res.arrayBuffer())
      const driverImageSignature = await pdfDoc.embedPng(driverSignatureBytes)

      const pages = pdfDoc.getPages()
      const firstPage = pages[1]
      const secondPage = pages[2]
      const thirdpage = pages[3]
      const pngDims = driverImageSignature.scale(0.2)
      firstPage.drawImage(driverImageSignature, {
          x: firstPage.getWidth() / 2 - pngDims.width / 2 - 20,
          y: firstPage.getHeight() / 2 - pngDims.height / 2 - 175,
          width: pngDims.width,
          height: pngDims.height,
      })
      secondPage.drawImage(driverImageSignature, {
          x: firstPage.getWidth() / 2 - pngDims.width / 2 - 200 ,
          y: firstPage.getHeight() / 2 - pngDims.height / 2 - 175,
          width: pngDims.width,
          height: pngDims.height,
      })
      thirdpage.drawImage(driverImageSignature, {
        x: firstPage.getWidth() / 2 - pngDims.width / 2 - 220 ,
        y: firstPage.getHeight() / 2 - pngDims.height / 2 - 55,
        width: pngDims.width,
        height: pngDims.height,
      })
      
      const usefulData = await pdfDoc.saveAsBase64({dataUri: true})
      setDataURL(usefulData)
      // submitPhoto(images)
      submitAgreement();
      submitForm(usefulData)

  }

  function submitAgreement() {
    ApiService.trainerAgreement("agreements", {trainerCode: userDetails.username, signed: 1})
  }

  async function submitForm(data: string) {
    let tempFile = new Blob([(await b64toBlob(data))], {type: 'application/pdf'})
    const size = tempFile.size;
    ApiService.submitTenstreetDocument("agreements", {
        ssn: ssn,
        fileId: 1,
        fileCode: 'TrainerTraineeAgreement',
        fileStorageType: 'encoded string',
        fileCategoryName: 'DQF',
        filename: userDetails.username + '_Trainer_Trainee_Agreements.pdf',
        fileSize: size,
        fileType: 'application/pdf',
        fileData: data,
        mode: 'PROD'
      })
      .then(() => {ApiService.sendEventCommand("Test Agreement", "eventCheck", "Testing agreement build")})
      .then(() => {
          toastStore.getState().setSuccessToast('Success. Your form was submitted.');
          // history.push('/agreements');
      });
  }

  const b64toBlob = (base64:any) => 
  fetch(base64).then(res => res.blob())

  useEffect(() => {
    console.log("userDetails", userDetails.trainerAgreement);
    ApiService.getDriverSsn("agreements").then(it => {
      setDriverHash(it);
      checkLink(it);
    });
  }, []);

  return (
      <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home" text=""/>
          </IonButtons>
          <IonTitle>Agreements</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonItem color="light" lines="none">
          <div className="item-container">
            <p>These documents include useful information about Western Express policy</p>
            <p className="subtitle">Please read each document in its entirety, and confirm your understanding of the following items (below): </p>
            <p style={{fontSize: "small", color: "#5096FF"}} onClick={() => setShowTrainingCode(true)}>- <u>Code of Conduct</u></p>
            <p style={{fontSize: "small", color: "#5096FF"}} onClick={() => setShowRelation(true)}>- <u>Trainer / Trainee Relationship Agreement</u></p>
            <p style={{fontSize: "small", color: "#5096FF"}} onClick={() => setShowSafetyBeltPolicy(true)}>- <u>Safety belt and sleeper berth occupant restraint training policy</u></p>
          </div>
        </IonItem>
        <IonItem color="light" lines="none">
          <div className="item-container">
            <p>Agreements Status: <br/>{userDetails.trainerAgreement === 1 ? <span style={{ color: "#2fd573", textAlign: "center"}}>You have signed and agreed.</span> : <span style={{ color: "#ff0000", textAlign: "center"}}>Please read the above items and sign below.</span>}</p>
          </div>
        </IonItem>
        {userDetails.trainerAgreement === 0 ?<>
        <IonItem lines="none" color="light">
             <div className='item-container'>
             <div className="input-container">
                 <input id="note-box" className="input" type="password" maxLength={9} placeholder=" " value={ssn} onChange={e => setSSN(e.target.value!)}/>
                 <p className="placeholder">SSN</p>
             </div>
             </div>
         </IonItem>
        <IonItem lines="none" color="light">
          <div className="item-container">
            <p>Signature</p>
            <div hidden={!showDriverSignature} style={{textAlign: "center", width: "90%", overflow: "hidden", backgroundColor: "white", margin: "auto", borderRadius: "20px" }} >
              <SignaturePad
              ref={driverSigCanvas}
              canvasProps={{ width: 500, height: 175, className: 'sigCanvas'}}
              />
            </div>
            <div className="item-container" style={{ display: "flex", justifyContent: "space-between"}}>
            {showDriverSignature ? <p onClick={saveDriver} style={{ color: "#212121"}}>Save</p> : <button onClick={fillForm} className="western-button">Submit</button>}
            {showDriverSignature ? <p style={{color: "#5096FF"}} onClick={clearDriver}>Clear</p>: <button onClick={saveDriver} className="button">Re-sign</button>}
            </div>
          </div>
        </IonItem> </>: null}
        
        <IonModal isOpen={showTrainingCode}>
          <IonHeader>
            <IonToolbar color="primary">
                <IonButtons>
                  <IonBackButton></IonBackButton>
                </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonCard color="gains" className="container" style={{ overflowY: "auto", height: "100%", margin: "auto"}}>
            <IonItem lines="none">
              <div className="item-container">
                  <p>Trainer / Trainee Code of Conduct</p>
              </div>
            </IonItem>
             
              <IonCardContent>
                <IonItem lines="none">
            <div className="item-container">
                <p className="subtitle">As a trainer or trainee for Western Express, I understand that I am held to a higher standard
                than a regular, over the road driver. I am considered a supervisor / trainee while he / she are in
                the care of the trainer / trainee. My actions and conduct must reflect the highest level of
                professionalism.</p>

                <p className="title">As a trainer / trainee for Western Express, I agree to the following:</p>
                <ol>
                    <li> I will treat my trainee / trainer with respect and dignity at all times.
                    </li>

                    <li> I will not subject my trainee / trainer to any form of harassment, including verbal or
                    physical</li>

                    <li> I will not place my trainer / trainee in any situation where he / she may feel threatened,
                    intimidated, or uncomfortable.</li>

                    <li> I will not view any material, printed, video, or otherwise, that could be deemed as
                    questionable in nature while the trainer / trainee are together on the Western Express
                    tractor.</li>

                    <li> I will not make any statement, joke, etc. that could be deemed as inappropriate to my
                    trainer / trainee, or in the presence of my trainer / trainee.</li>

                    <li> I understand that any physical contact with my trainer / trainee is expressly forbidden.</li>

                    <li> I will keep a neat and clean professional appearance of my person and my truck. I will
                    shower regularly and maintain appropriate, professional personal hygiene.</li>
                </ol>
            </div>
            </IonItem>
            <IonItem lines="none">
              <div>
                  <button className="western-button submit" onClick={() => {setShowTrainingCode(false); setAgreeConduct(true)}}>I Understand</button>
              </div>
            </IonItem>
        </IonCardContent>
          </IonCard>
        </IonModal>
        <IonModal isOpen={showRelation}>
          <IonHeader>
            <IonToolbar color="primary">
                <IonButtons>

              <IonBackButton></IonBackButton>
                </IonButtons>

            </IonToolbar>
          </IonHeader>
          <IonCard color="gains" className="container" style={{ overflowY: "auto", height: "100%", margin: "auto"}}>
            <IonItem lines="none">
              <div className="item-container">
                <p>Trainer / Trainee Relation Agreement</p>
              </div>

            </IonItem>
                 
            
        
              <IonCardContent>
                <IonItem lines="none">
            <div className="item-container">
              <p className="title">Trainees have the responsibility to follow the instructions of the Trainer and learn the
              correct way to operate company equipment.</p>

              <ol>
                <li> DRIVER TRAINERS ARE IN CHARGE OF THE TRUCK.</li>

                <li> If there are any questions on this matter, refer to rule #1.</li>

                <li> Driver Trainees must drive following the instructions of the Driver Trainer.
                </li>

                <li> Driver Trainees must thoroughly learn all aspects of paperwork and QUALCOMM
                procedures. You will be required to show a log book with a log for each day you are
                out with a trainer</li>

                <li> Trainees must complete all phases of the training program as outlined by the
                Training Department</li>

                <li> During the training period, each Trainee will be evaluated by trainer and the
                Training Department of Western Express. The trainer will fill out a report each day
                and will be reviewed by the Trainer and Western Express Training.
                </li>

                <li> ALCOHOL POLICY it is a violation of Western Express Training Department policy
                for the trainer or trainee to use or possess any alcoholic beverages during the
                duration of training. You can be assigned a Trainer and Dispatch with little or no
                notice and must be able to go without being in violation of D.O.T. regulations and /
                or company policy. Failure to comply will result in termination from Western
                Express.</li>

                <li> DRUG POLICY failure to comply with D.O.T. and company requirements will result
                in termination from Western Express.
                </li>

                <li> The trainee should drive all available hours each day. The more time behind the
                wheel, the more experience and easier it will be when the trainee goes solo.
                </li>

                <li> TRUCK RECOVERIES  At times, you may be asked by operations to split and help
                recover a truck. Only Vice Presidents’, Terminal Manager, or the
                Training Manager may authorize this. Failure to abide by this will result in your
                removal from the training program</li>

                <li> Backing. Trainee will do all backing under supervision of the Trainer. Get out of
                the truck and check area to the rear and side of the unit before attempting
                maneuvers. GOAL. Get out and Look.</li>

                <li> Remember …. You are training your trainee to become a Profession Solo Driver.
                When running as a team on team freight, explain to your trainee if he were running
                solo, how far he could legally run, where he would need to plan to take his break for
                the day etc. Some work will have to be shared during the dispatch cycle, but the
                Driver trainer is there to ensure the Trainee will be able to perform as a
                Professional Solo Driver. This means the trainee will perform the majority of all
                operations, including communications with customers, operations, and paperwork.
                </li>

                <li> Routing. Use the route sent to your Qualcomm. If for some reason a route is not
                available use the shortest, legal route. Trainee should plan each trip and discuss the
                same with the Trainer.</li>

                <li> Trainee shall not use a cell phone or other electronic devices while on duty. On duty
                time should be used to focus on learning! These devices are a distraction that
                hinders training.</li>

                <li> Each person has an important role in successfully completing the training program.
                If tensions arise to the point of stopping training, it is essential that the trainer or
                trainee contact the Training Manager.
                </li>

                <li> No trainee will be required to sleep in a top bunk without factory equipped netting
                systems while the truck is in motion. This is due to state laws requiring a
                securement device for all passengers.
                </li>

                <li> <strong> While on duty and especially while driving the truck, a trainee cannot use cell
                phone, iPods, cd’s, or any other electronic devices!!! </strong>
                </li>

                <li> If a trainee chooses to get off the trainer’s truck and/or go home for any unrelated
                work issues, Western Express will NOT pay for a bus ticket. A ticket may be
                purchased at trainee’s request, but will come out of the trainee’s next paycheck.</li>
              </ol>

            </div>
            </IonItem>
            <IonItem lines="none">
              <div>
                  <button className="western-button submit" onClick={() => {setShowRelation(false); setAgreeRelation(true)}}>I Understand</button>
              </div>
            </IonItem>
        </IonCardContent>
          </IonCard>
        </IonModal>

        <IonModal isOpen={showSafetyBeltPolicy}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons>

                <IonBackButton></IonBackButton>
              </IonButtons>

            </IonToolbar>
          </IonHeader>
          <IonCard color="gains" className="container" style={{ overflowY: "auto", height: "100%", margin: "10px auto"}}>
            <IonItem lines="none">
              <div className="item-container">
                <p style={{textAlign: "center"}}>
                  WESTERN EXPRESS, INC.<br/>
                  SAFETY BELT AND SLEEPER BERTH OCCUPANT RESTRAINT<br/>
                  TRAINING POLICY</p>
              </div>

            </IonItem>

            <IonCardContent>
              <IonItem lines="none">
                <div className="item-container">
                  <p>
                    We care about your safety and your trainee’s safety! As you have been made aware, significant injuries can
                    occur from lack of using safety and restraint equipment. It is our policy that every operator of a commercial
                    motor vehicle and all occupants of any vehicle shall wear safety belts and use sleeper berth restraints. As a
                    trainer, you have a responsibility to carry out this policy.
                  </p>
                  <br/>
                  <p>
                    I will (a) regularly inspect my vehicle’s safety belts and sleeper berth occupant restraints to ensure that they
                    are in good working order; (b) ensure any trainee or passenger in my vehicle is trained on how to properly
                    use the safety restraints in accordance with Western Express’s Safety Belt and Sleeper Berth Occupant
                    Restraint Policy and this policy, and (c) ensure that, any trainee or passenger is properly secured while in
                    the passenger seat or while in the sleeper berth.
                  </p>
                  <br/>
                  <p>
                    If repair or replacement of safety equipment is needed, I will contact my driver manager or terminal shop to
                    coordinate repairs immediately. I understand that Western will enforce safety belt and sleeper berth
                    occupant restraint use in the same manner as other work rules.
                  </p>
                  <br/>
                  <p>
                    <strong>
                      THE COMMERCIAL MOTOR VEHICLE SHALL NOT BE OPERATED WHILE ANYONE IS NOT SAFELY RESTRAINED.
                      FAILURE EITHER TO USE OR TO REQUIRE OTHERS TO USE ANY REQUIRED RESTRAINT WILL BE ENTIRELY AT
                      YOUR OWN RISK AND WITH KNOWLEDGE OF THE DANGER INVOLVED. YOU AGREE TO ASSUME ALL RISK OF
                      INJURY ARISING FROM ANY FAILURE TO USE THE RESTRAINTS. NON-COMPLIANCE OF THIS POLICY MAY
                      RESULT IN DISCIPLINARY ACTION UP TO AND INCLUDING DISCHARGENON-COMPLIANCE OF THESE TRAINER
                      POLICIES MAY RESULT IN DISCIPLINARY ACTION UP TO AND INCLUDING DISCHARGE.
                    </strong>
                  </p>
                </div>
              </IonItem>
            <IonItem lines="none">
              <div>
                <button className="western-button submit" onClick={() => {setShowSafetyBeltPolicy(false); setAgreeSafetyBeltPolicy(true)}}>I Understand</button>
              </div>
            </IonItem>
            </IonCardContent>
          </IonCard>
        </IonModal>
        <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toast}
            color="success"
            duration={1000}/>

        <IonToast
            isOpen={show_AlertToast}
            onDidDismiss={() => setShow_AlertToast(false)}
            message={alertToast}
            color="danger"
            duration={1000}/>
        </IonContent>
      </IonPage>
      
    );
};

export default Agreements;
