import React from "react";
import {config, version} from "../Constants"

export const checkOS = () => {
    let os: any = []
    os = navigator.userAgent.toLowerCase()
    console.log("os 1", os);
    var match = os.match(/android\s([0-9\.]*)/i);
    console.log("os 2", match);
    if (match !== null) {   
        if (parseInt(match[1]) > 10) {
            return true
        } else {
            return false
        }
    } else {
        return true;
    }
}
