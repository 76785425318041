import {create} from "zustand";
import {DriverProfile} from "./model/DriverProfile";
import {ApiService} from "./ApiService";
import {UserDetails} from "./model/UserDetails";
import {LoginResponse} from "./model/LoginResponse";
import {LoadDetails} from "./model/LoadDetails";
import {StopDetails} from './model/StopDetails';
import {PushNotificationsService} from "./PushNotificationsService";
import {LoadInfo} from "./model/LoadInfo";
import {UserType} from "./enum/UserType";
import {config} from "../Constants";
import { StopInfo } from "./model/StopInfo";

export class AuthService {
    public static isLoggedIn(): boolean {
        return !!localStorage.getItem('token') && !!localStorage.getItem('username')?.toUpperCase();
    }

    public static processLogin(data: LoginResponse, credentials: { username: string, password: string }, loginData: { agreedToTerms: boolean, saveCredentials: boolean }): void {
        localStorage.setItem('token', data.loginToken);
        localStorage.setItem('type', data.type);
        localStorage.setItem('username', credentials.username);
        if (loginData.saveCredentials) {
            localStorage.setItem('save', 'true');
            localStorage.setItem('password', credentials.password);
        } else {
            localStorage.removeItem('save');
            localStorage.removeItem('password');
        }
        if (data.type === 'driver_manager') {
            localStorage.setItem('dmUsername', credentials.username);
            localStorage.setItem('dmToken', data.loginToken);
        }
        userStore.getState().clearUserData();
        userStore.getState().performLogin(credentials.username);
        window.history.pushState('', '', '/');
    }

    public static processLogout(): void {
        if (localStorage.getItem('save') === 'true') {
            // If remember me was checked then we keep only the credentials from local storage
            // We remove everything else that is in there
            const username = localStorage.getItem('username');
            const password = localStorage.getItem('password');
            localStorage.clear();
            localStorage.setItem('username', username || '');
            localStorage.setItem('password', password || '');
            localStorage.setItem('save', 'true');
        } else {
            localStorage.clear();
        }
        userStore.getState().clearUserData();
        window.history.pushState('', '', '/');
    }
}

export interface UserState {
    initialized: boolean;
    isLoggedIn: boolean;
    performedLogin: boolean;
    driver?: DriverProfile;
    user?: UserDetails;
    dmUserCode?: string;
    loadNumber?: string;
    loadInfo?: LoadInfo;
    stopInfo?: StopInfo
    profilePictureUrl?: string;
    username: string;
    type: UserType;
    fetchUserData: () => Promise<void>;
    clearUserData: () => void;
    performLogin: (username: string) => void;
    reloadDriverProfile: () => Promise<void>;
}


export const userStore = create<UserState>((set, get) => ({
    initialized: false,
    isLoggedIn: AuthService.isLoggedIn(),
    performedLogin: false,
    driver: undefined,
    user: undefined,
    loadNumber: undefined,
    loadInfo: undefined,
    stopInfo: undefined,
    profilePictureUrl: '',
    username: localStorage.getItem('username') || '',
    type: localStorage.getItem('type') as UserType,
    fetchUserData: async () => {
        if (!AuthService.isLoggedIn()) {
            set({initialized: true})
            return;
        }
        userSessionStore.getState().setSessionTimer();
        const type = localStorage.getItem('type') as UserType;

        const driver = type !== UserType.ADMIN && type !== UserType.INSPECTOR && type !== UserType.YARDDOG && type !== UserType.GUARDDOG && type !== UserType.DRIVER_MANAGER ? await ApiService.getDriverProfile("login") : undefined;
        const promiseResult = await Promise.all([
            ApiService.getUser("login"),
            driver?.unit ? (await ApiService.getLoadDetails("login", {unit: driver.unit}).catch(e => {
                return undefined
            })) : Promise.resolve(undefined)
        ])
        const user = promiseResult[0] as UserDetails;
        const load = (promiseResult[1] as LoadDetails)?.load;
        const stops = (promiseResult[1] as unknown as StopDetails)?.stops;
        console.log("promiseResult", promiseResult[1]);
        const loadNumber = load?.loadNumber;

        set({
            driver,
            user,
            loadNumber: loadNumber,
            loadInfo: load,
            stopInfo: stops,
            dmUserCode: user.dmcode,
            isLoggedIn: AuthService.isLoggedIn(),
            initialized: true,
            username: localStorage.getItem('username') || '',
            type
        });
        localStorage.setItem('userId', user.id)
        if (type === UserType.ADMIN) {
            localStorage.setItem("dmType", "admin");
            localStorage.setItem('dmUserCode', user.dmcode);
        } else if (type === UserType.DRIVER_MANAGER) {
            localStorage.setItem("dmType", "driver_manager");
            localStorage.setItem('dmUserCode', user.dmcode);
        }

        if (get().performedLogin && user?.id && type !== UserType.ADMIN && type !== UserType.INSPECTOR && type !== UserType.YARDDOG && type !== UserType.GUARDDOG && type !== UserType.DRIVER_MANAGER) {
            PushNotificationsService.registerAfterAppLogin(user.id).then(() => {
                set({performedLogin: false});
            });
        }

    },
    clearUserData: () => {
        set({isLoggedIn: false, driver: undefined, user: undefined, loadNumber: undefined, loadInfo: undefined, profilePictureUrl: '', username: '', type: undefined});
    },
    performLogin: (username: string) => {
        set({performedLogin: true, initialized: false, username});
    },
    reloadDriverProfile: async () => {
        if (localStorage.getItem("type") !== "inspector" || localStorage.getItem("type") !== "yarddog" || localStorage.getItem("type") !== "guarddog" || localStorage.getItem("type") !== "driver_manager" || localStorage.getItem("type") !== "admin") {
            const driver = await ApiService.getDriverProfile("login");
            set({driver});
        }
    }
}));

export interface UserSessionState {
    timer?: number;
    setSessionTimer: () => void;
}

export const userSessionStore = create<UserSessionState>((set, get) => ({
    timer: undefined,
    setSessionTimer: () => {
        if (!config.sessionTimerEnabled) {
            return;
        }

        if (get().timer) {
            clearTimeout(get().timer);
        }
        set({timer: setTimeout(() => AuthService.processLogout(), 900000)});
    }
}));

export interface UserPushNotificationState {
    received: boolean
    title: string
    responseId: number,
    id: string
    data: {}
}

export const UserPushNotificationStore = create<UserPushNotificationState>((set, get) => ({
    received: false,
    title: "",
    responseId: 0,
    id: "",
    data: {}
}))