import {Capacitor} from "@capacitor/core";
import {PushNotifications} from "@capacitor/push-notifications";
import {ApiService} from "./ApiService";
import { initializeApp  } from "firebase/app"
import {isPlatform} from "@ionic/react";
import { onBackgroundMessage } from "firebase/messaging/sw"
import { getMessaging } from "firebase/messaging/sw";
import { UserPushNotificationStore } from "./AuthService";

export class PushNotificationsService {
    
    static eventArray: any = [];
    
    static firebaseConfig = {
        apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
        authDomain: "western-express-5002c.firebaseapp.com",
        databaseURL: "https://western-express-5002c.firebaseio.com",
        projectId: "western-express-5002c",
        storageBucket: "western-express-5002c.appspot.com",
        messagingSenderId: "799211811572",
        appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
        measurementId: "G-V5MNJV768B"
      }
      
    static app = initializeApp(PushNotificationsService.firebaseConfig)

    // static messaging = getMessaging(PushNotificationsService.app);
      
    public static async registerAfterAppLogin(userId: string) {
        if (Capacitor.isPluginAvailable('PushNotifications')) {
            if (JSON.parse(localStorage.getItem('pushNotificationPermission')!) === null) {
                const permissionGranted = PushNotifications.requestPermissions();
                if ((await permissionGranted) && JSON.parse(localStorage.getItem('pushNotificationPermission')!) === null) {
                    console.log("PushNotifications register 1");
                    PushNotifications.register();
                } else {
                    localStorage.setItem('refusalDate', JSON.stringify(new Date()));
                }
            } else {
                console.log("PushNotifications register 2");
                PushNotifications.register();
            }

            PushNotifications.addListener('registration',
                async (token) => {
                    console.log("token", token.value);
                    localStorage.setItem('pushNotificationPermission', token.value);
                    await ApiService.registerDeviceToken("pushNotificationsService", {platform: isPlatform('ios') ? 'ios' : 'android', userId})
                    .then(it => console.log("pushNotificationsService", it))
                }
            );

            PushNotifications.addListener('registrationError',
                (error: any) => {
                    console.log('Error on registration: ' + JSON.stringify(error))
                    alert('Error on registration: ' + JSON.stringify(error));
                }
            );
        }
    }


    public static registerAfterAppLoad() {
        if (Capacitor.isPluginAvailable('PushNotifications') && this.eventArray.length <= 1) {
            this.eventArray.pop();
            PushNotifications.removeAllListeners();
            this.eventArray.push('pushNotificationReceived')
            for (let index = 0; index < this.eventArray.length; index++) {
                PushNotifications.addListener('pushNotificationReceived', (notification) => {
                        // alert(notification.body);
                        UserPushNotificationStore.setState({received: true, title: notification.title, id: notification.id, responseId: notification.data.responseId, data: notification.data})
                        console.log("pushNotificationReceived", notification);
                    }
                );
                PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
                    console.log("notification action", notification.notification);
                    if (notification.notification.data.title === "Echo Test") {
                        UserPushNotificationStore.setState({received: true, title: notification.notification.data.title, id: notification.notification.id, responseId: notification.notification.data.responseId, data: notification.notification.data})
                    }
                })
            }
        }
    }

    public static getDeliveredNotifications = async () => {
        const notificationList = await PushNotifications.getDeliveredNotifications();
        for (let index = 0; index < notificationList.notifications.length; index++) {
            const element = notificationList.notifications[index];
            if (element.title === "Echo Test") {
                UserPushNotificationStore.setState({received: true, title: element.title, data: {}})
            }
        }
        console.log('delivered notifications', notificationList);
    }

    public static removeNotification = (id: any) => {
        PushNotifications.removeDeliveredNotifications(id)
    }

    public static removeAllNotification = () => {
        PushNotifications.removeAllDeliveredNotifications()
    }

}
