import React, {useEffect, useState} from "react";
import {IonButtons, IonHeader, IonItem, IonItemGroup, IonModal, IonToolbar} from "@ionic/react";
import {UserPushNotificationStore, userStore} from "../../api/AuthService";
import {version as appVersion} from '../../Constants';

const PushNotificationWindow = (props: any) => {

    const [modalOpen, setModalOpen] = useState(true);
    const [pushData, setPushData] = useState<any>({});
    const [pushTitle, setPushTitle] = useState<any>({}); 

    const sub = UserPushNotificationStore.subscribe((state: any) => setPushData(state.data))
    const sub2 = UserPushNotificationStore.subscribe((state: any) => setPushTitle(state.title))


    const accept = (response: any) => {
        console.log("first response", response);
        props.onAccept(response);
    }

    useEffect(() => {
        console.log("pushData", pushData, props.showNotification);
      }, [pushData]);

    function getPushType() {
       console.log("pushType", pushTitle, pushData)
       if (pushTitle === "Echo Test") { 
        return <IonItemGroup>
                    <IonItem lines="none" color="light">
                        <div style={{height: 'auto'}}>
                            <p style={{whiteSpace: "pre-line"}}>Your load is scheduled to be delivered on Monday. Please confirm you are good to make it on time.</p>
                        </div>
                    </IonItem>
                    <IonItem lines="none" color="light">
                        <div className="item-container">
                            <div style={{display: "flex"}}>
                                <button className="app-btn app-btn-primary w-100 text-center d-block" onClick={() => accept("yes")}>Yes</button>
                                <button className="app-btn app-btn-primary w-100 text-center d-block" onClick={() => accept("no")}>No</button>
                            </div>
                        </div>
                    </IonItem>
                </IonItemGroup>
       } else if (pushTitle === "Monday Delivery") {
        return <IonItemGroup>
                    <IonItem lines="none" color="light">
                        <div style={{height: 'auto'}}>
                            <p style={{whiteSpace: "pre-line"}}>Your load is scheduled to be delivered on Monday. Please confirm you are good to make it on time.</p>
                        </div>
                    </IonItem>
                    <IonItem lines="none" color="light">
                        <div className="item-container">
                            <div style={{display: "flex"}}>
                                <button className="app-btn app-btn-primary w-100 text-center d-block" onClick={() => accept("yes")}>Yes</button>
                                <button className="app-btn app-btn-primary w-100 text-center d-block" onClick={() => accept("no")}>No</button>
                            </div>
                        </div>
                    </IonItem>
                </IonItemGroup>
       } else {
        return
       }
    }

    return <IonModal isOpen={modalOpen} backdropDismiss={false} cssClass="block-modal">
         <div style={{display: "block"}}>
            <IonHeader >
            <IonToolbar color="primary">
                <IonButtons slot="start">
                <button onClick={ function() { props.cancel() }} style={{background: "transparent"}}>
                    <h6>X</h6>
                </button>
                </IonButtons>
                <IonItem style={{"--background": "#212121"}}>
                        <div className="item-container">
                            <h1 style={{color: "#fff"}}>Monday Delivery</h1>
                        </div>
                </IonItem>
            </IonToolbar>
            </IonHeader>
        {getPushType()}
        </div>
    </IonModal>
}
export default PushNotificationWindow;
